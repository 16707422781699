export default defineNuxtRouteMiddleware((to, from) => {
    const userStore = useUserStore()

    if (!userStore.isAuthed) {
        return navigateTo({
            name: PAGE.LOGIN,
        })
    }

    // TODO: uncomment when guest login feature is ready
    // if (userStore.isGuest) {

    //     if (from.fullPath !== to.fullPath) {
    //         return;
    //     }

    //     return navigateTo(
    //         {
    //             name: PAGE.NUDGE_REGISTER,
    //             query: {
    //                 'redirectTo': from.path
    //             }
    //         }
    //     )
    // }
})
